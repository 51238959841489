export default class Canvas {
    width
    height
    lineHeight
    element
    ctx

    constructor (width, lineHeight) {
        this.width = width
        this.lineHeight = lineHeight
        this.height = lineHeight 

        this.element = document.createElement('canvas')
        this.element.width = this.width
        this.element.height = this.height
        this.ctx = this.element.getContext('2d', { willReadFrequently: true })
    }

    line () {
        this.addHeight(16)
        this.ctx.beginPath();
        this.ctx.lineWidth = 2;
        this.ctx.moveTo(0,this.height - 4);
        this.ctx.lineTo(this.width ,this.height - 4);
        this.ctx.stroke();

        return this
    }

    wrapText(ctx, phrase, maxPxLength, textStyle) {
        var wa=phrase.split(" "),
            phraseArray=[],
            lastPhrase=wa[0],
            measure=0,
            splitChar=" ";
        if (wa.length <= 1) {
            return wa
        }
        ctx.font = textStyle;
        for (var i=1;i<wa.length;i++) {
            var w=wa[i];
            measure=ctx.measureText(lastPhrase+splitChar+w).width;
            if (measure<maxPxLength) {
                lastPhrase+=(splitChar+w);
            } else {
                phraseArray.push(lastPhrase);
                lastPhrase=w;
            }
            if (i===wa.length-1) {
                phraseArray.push(lastPhrase);
                break;
            }
        }
        return phraseArray;
    }

    text(text, alignment = 'left', size = 28,textRight=null) {
        let wrappedText = this.wrapText(this.ctx, text, this.width - 10, `${size}px Arial`);
         
        for (let i = 0; i < wrappedText.length; i++) {
            if (size == null) size = 28
            this.addHeight(size + 4)
            let w
            switch (alignment) {
                case 'left':  w = 3             ;break;
                case 'center':w = this.width/2  ;break;
                case 'right': w = this.width - 3;break;
            }
    
            this.ctx.font = size+'px arial'
            this.ctx.textAlign = alignment
            this.ctx.fillStyle = 'black'
    
            if (textRight){
                this.ctx.textAlign = 'left'
                this.ctx.fillText(wrappedText[i],3,this.height-4)
                this.ctx.textAlign = 'right'
                this.ctx.fillText(textRight,this.width - 3,this.height-4)
    
            }else{
                this.ctx.fillText(wrappedText[i],w,this.height-4)
            }
        }
      
        return this
    }

    addHeight(size = this.lineHeight){
        let content = this.ctx.getImageData(0,0,this.width,this.height)
        this.element.height += size
        this.ctx.putImageData(content,0,0)

        this.height = this.element.height
        return this
    }

    fillBackground(color = 'white') {
        // Cria um canvas temporário
        var tempCanvas = document.createElement('canvas');
        var tempCtx = tempCanvas.getContext('2d');

        // Configura o tamanho do canvas temporário para corresponder ao canvas original
        tempCanvas.width = this.width; 
        tempCanvas.height = this.height;

        // Pinta o fundo do canvas temporário
        tempCtx.fillStyle = color;
        tempCtx.fillRect(0, 0, this.width, this.height);

        // Desenha o conteúdo do canvas original no canvas temporário
        tempCtx.drawImage(this.element, 0, 0);

        // Limpa o canvas original e copia o conteúdo do temporário para ele
        this.ctx.clearRect(0, 0, this.width, this.height);
        this.ctx.drawImage(tempCanvas, 0, 0);
    }

    getElement(){
        this.fillBackground();
        return this.element
    }
}
